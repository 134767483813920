<script setup lang="ts">
import StickyFooterBtn from "~/components/StickyFooterBtn.vue";

interface Props {
  buttonDisabled?: boolean;
  hasWideWrapper?: boolean;
  isLoading?: boolean;
  buttonText: string;
  enableAdditionalButton?: boolean;
  showLoginHint?: boolean;
}

const { t } = useNuxtApp().$i18n;
const router = useRouter();
const checkoutUrl = router.resolve({ path: "/onboarding" }).href;

const props = withDefaults(defineProps<Props>(), {
  buttonDisabled: false,
  hasWideWrapper: false,
  isLoading: false,
  showLoginHint: false,
  buttonText: "",
  enableAdditionalButton: false,
});

const showAdditionalButton = ref(false);
const additionalButtonWrapper = ref<HTMLDivElement | null>(null);
const additionalButton = ref<HTMLButtonElement | null>(null);

interface Emits {
  (e: "buttonClick"): void;
}

const emit = defineEmits<Emits>();

function onVisualViewportResize() {
  setTimeout(() => {
    showAdditionalButton.value = (window.visualViewport?.height ?? 550) < 550;

    if (showAdditionalButton.value) {
      additionalButtonWrapper.value?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, 300);
}

function onAdditionalButtonClick() {
  if (props.buttonDisabled || !showAdditionalButton.value) {
    return;
  }

  emit("buttonClick");
}

onMounted(() => {
  if (!props.enableAdditionalButton) {
    return;
  }

  if (
    navigator.userAgent.includes("Mobile") &&
    !navigator.userAgent.includes("Instagram") &&
    !navigator.userAgent.includes("Firefox")
  ) {
    window.visualViewport?.addEventListener("resize", onVisualViewportResize);

    setTimeout(() => {
      onVisualViewportResize();
    }, 500);
  }
});
</script>

<template>
  <div
    class="!tw-flex !tw-flex-col !tw-justify-between md:!tw-justify-start md:tw-gap-5"
    :class="[props.hasWideWrapper ? 'onboarding-content' : 'onboarding-content-progress']"
  >
    <div ref="additionalButtonWrapper">
      <slot />

      <div v-if="props.enableAdditionalButton" class="tw-flex tw-justify-center">
        <div
          ref="additionalButton"
          class="bg-gradient-primary tw-text-md tw-m-auto tw-min-w-full tw-rounded-3xl tw-border-0 tw-px-8 tw-py-3 tw-text-center tw-font-medium tw-text-white tw-transition-opacity tw-duration-300"
          :class="{ 'tw-cursor-pointer tw-opacity-100': showAdditionalButton, 'tw-opacity-0': !showAdditionalButton }"
          @click="onAdditionalButtonClick"
        >
          <span>{{ buttonText }}</span>
        </div>
      </div>
    </div>

    <div class="tw-grid tw-justify-items-center">
      <StickyFooterBtn
        class="py-3 md:-tw-mx-auto tw-block tw-w-full tw-self-center tw-transition-opacity tw-duration-300 md:tw-max-w-[41rem]"
        :class="{
          'tw-opacity-0': showAdditionalButton,
          'tw-opacity-100': !showAdditionalButton,
          'tw-px-3': hasWideWrapper,
        }"
        :disabled="buttonDisabled"
        col-classes="col-lg-7 md:!tw-w-3/5"
        @submit="$emit('buttonClick')"
      >
        <span v-if="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true" />
        {{ buttonText }}
      </StickyFooterBtn>
      <div
        v-if="showLoginHint"
        class="!tw-text-center !tw-text-sm"
        v-html="t('app.prosignup.pro.label.no_coupon', [checkoutUrl])"
      ></div>
    </div>
  </div>
</template>
