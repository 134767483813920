<script setup lang="ts">
import type { VoucherCodeScreen } from "~/types/onboarding/onboardingScreen";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import AppInput from "~/components/app/AppInput.vue";
import { useVoucherStore } from "~/stores/useVoucherStore";
import type { Voucher } from "~/types/voucher";

interface Props {
  screen?: VoucherCodeScreen;
}

interface Emits {
  (e: "next", value: { nextScreenId?: string; voucher: Voucher; voucherCode: string }): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const voucherStore = useVoucherStore();
const { getValidVoucherCodeDetails } = voucherStore;
const { t } = useNuxtApp().$i18n;

const code = ref<string>("");
const voucher = ref<Voucher | undefined>();
const error = ref<string | undefined>();
const isLoading = ref<boolean>(false);

async function next() {
  error.value = undefined;
  isLoading.value = true;

  if (!code.value) {
    return;
  }

  try {
    const voucherDetails = await getValidVoucherCodeDetails(code.value);
    voucher.value = voucherDetails;

    emit("next", {
      nextScreenId: props.screen?.nextStep,
      voucher: voucherDetails,
      voucherCode: code.value,
    });
  } catch (e) {
    error.value = t("app.prosignup.pro.error.coupon_invalid");
    isLoading.value = false;
  }
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!code || isLoading"
    :button-text="$t('app.propayment.step2.button.entercoupon')"
    :is-loading="isLoading"
    show-login-hint
    @button-click="next"
  >
    <h1 class="text-header-md !tw-text-center">{{ $t("app.propayment.step2.label.coupon") }}</h1>

    <AppInput
      v-model="code"
      :error="error"
      :placeholder="$t('app.propayment.step2.label.inputcoupon')"
      :input-class="{
        'is-invalid': error && !voucher,
        'is-valid': voucher,
      }"
      :disabled="isLoading"
      class="coupon-input"
      field="couponCode"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>

<style>
.coupon-input {
  margin: auto;
  max-width: 600px;
}
</style>
